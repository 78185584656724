@import './carousel.css';
@import './datePicker.css';
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600;700&display=swap'); */

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #fff;
  color: #5e5e5e;
  font-size: 1rem;
  font-display: swap;
   font-family: var(--font-montserrat);
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 24px;
}
.heroBannerImage>div img{
  transform: scale(1) !important;
}
@media screen and (max-width: 1023px) {
  .heroBannerImage>div {
    min-height: 380px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .heroBannerImage>div {
    min-height: 320px;
  }
}